<template>
    <div class="schedling">
        <div style="display: flex;">
            <Headbuttom></Headbuttom>
            <Tabheader @handRegister="handRegister" @handFromshow="handFromshow" :titlename="titlename"></Tabheader>
        </div>
        
         <!-- <div class="attheadfrom" v-show="flag">
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item label="名称">
                    <el-input v-model="formInline.user" placeholder="规则名称"></el-input>
                </el-form-item>
                <el-form-item label="开始时间">
                    <el-date-picker
                    v-model="value1"
                    type="date"
                    placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间">
                    <el-date-picker
                    v-model="value1"
                    type="date"
                    placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">查询</el-button>
                </el-form-item>
            </el-form>
        </div> -->
        <Tables :tableLabel="tableLabel" @handlpages="handlpages" @handleSizeChange="handleSizeChange" @handlerow="handlerow" :tableData="tableData" :flags="flags" :configs="configs" :operation="operation"></Tables>
        <!-- <Schedulingdolg ref="schedulingdolg"></Schedulingdolg> -->
        <!-- 弹窗 -->
        <el-dialog
            :title="titlels"
            :visible.sync="centerDialogVisible"
            width="30%"
            append-to-body
            center>
            <el-form ref="form" :model="form" label-width="90px">
                <el-form-item :label="$t('devtable.jjrmc')">
                    <el-col :span="22">
                       <el-input v-model="form.name"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item :label="$t('devtable.ksrq')">
                    <el-col :span="22">
                        <!-- <el-date-picker type="date" placeholder="选择日期" v-model="form.date_start" style="width: 100%;">

                        </el-date-picker> -->

                         <el-date-picker
                            style="width: 100%;"
                            v-model="form.date_start"
                            type="date"
                            :placeholder="$t('devtable.xzrq')"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-col>
                </el-form-item>
                <el-form-item :label="$t('devtable.jsrq')">
                    <el-col :span="22">
                        <!-- <el-date-picker type="date" placeholder="选择日期" v-model="form.date_end" style="width: 100%;"></el-date-picker> -->
                        <el-date-picker
                            style="width: 100%;"
                            v-model="form.date_end"
                            type="date"
                            :placeholder="$t('devtable.xzrq')"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-col>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" v-if="housflag" @click="handjiaqadd">{{$t('tablename.qd')}}</el-button>
                <el-button type="primary" v-else @click="handjiaqedit">{{$t('devtable.xg')}}</el-button>
            </span>
        </el-dialog>
        <Cleadr ref="cleadr" @clearhand="clearhand" :data="clearldata"></Cleadr>
    </div>
</template>
<script>
import Tables from '@/components/table'
import Tabheader from './components/tabheader'
import { formatDate } from '@/utils/date'
import Cleadr from './components/cleadr'
export default {
    components:{
        Tables,
        Tabheader,
        Cleadr
        // Schedulingdolg
    },
    data(){
        return{
            
            titlels:this.$t('devtable.tjjr'),
            housflag:true,
            id:"",

            form: {
                name: '',
                date_start:'',
                date_end:''
            },
            value1:"",
            centerDialogVisible: false,
            flag:false,
            formInline: {
                user: '',
            },
            
            
            tableData: [],
            flags: {
                show: false, // 多选是否显示
                ishow: false // 序号是否显示
            },
            // 分页数据
            configs: {
                page_index:1,
                pagesize: 10,
                total: 0,
                pageCount:0,
                loading: false // 加载状态
            },
            
            clearldata:{},
            UserInfo:''
        }
    },
    computed:{
        titlename:function(){
            return{
                name:this.$t('devtable.tjjr'),
                placeholdername:this.$t('devtable.qdjcx')
            }
        },
        operation:function(){
            return{
                width:'200px',
                data:[
                {
                    name: this.$t('devtable.xg'),
                    type: 'primary'
                  },
                  {
                    name: this.$t('tablename.sc'),
                    type: 'danger'
                  },
                ]
            }
        } ,
        tableLabel:function(){

        return[
                {
                    prop: 'name',
                    label: this.$t('devtable.mc'),
                    align: 'center'
                },
                {
                    prop: 'date_start',
                    label: this.$t('devtable.ksrq'),
                    align: 'center'
                },
                {
                    prop: 'date_end',
                    label: this.$t('devtable.jsrq'),
                    align: 'center'
                },
            ]
        } 
    },
    mounted(){
        this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
        this.getholidy()
    },
    methods:{
        // 添加假期
        handjiaqadd(){
            let data={
                method:"att.restday.add",
                agent_id:this.UserInfo.agent_id,
                name:this.form.name,
                date_start:Math.round(new Date(this.form.date_start) / 1000),
                date_end:Math.round(new Date(this.form.date_end) / 1000)
            }
            // console.log(data)
            // return
            this.$serve(data).then(res=>{
                if(res.data.code == 0){
                    this.$message({
                        type: 'success',
                        message: '添加成功!'
                    })
                    this.getholidy()
                    this.centerDialogVisible = false;
                }else{
                    this.$message({
                        type: 'error',
                        message: '添加失败!'
                    })                    
                }
            })
            this.form.name=""
            this.form.date_start=""
            this.form.date_end=""
        },
        // 获取假期列表
        getholidy(v){
            let data={
                method:'att.restdays.get',
                agent_id:this.UserInfo.agent_id,
                page_index:v?v:1,
                page_size:this.configs.pagesize
            }
            this.$serve(data).then(res=>{
                if(res.data.code==0){
                    let data=res.data.data.records
                    this.configs.total= res.data.data.recordCount
                    this.configs.pageCount = Math.ceil(this.configs.total / this.configs.pagesize)
                    data.forEach(element => {
                        element.date_end= formatDate(new Date(element.date_end*1000), 'yyyy-MM-dd')
                        element.date_start= formatDate(new Date(element.date_start*1000), 'yyyy-MM-dd')
                    });
                    this.tableData = data
                }
            })
        },
        // 列表按钮点击
        handlerow(v,name){
            if(name=="删除" || name=="Delete"){
                this.clearldata={
                    id:v.id,
                    method:'att.restday.delete'
                }
                this.$refs.cleadr.dialogVisible = true
            }else{
                let data={
                    method:'att.restday.get',
                    id:v.id
                }
                this.id = v.id
                this.$serve(data).then(res=>{
                    if(res.data.code==0){
                        let redata = res.data.data
                        this.form.name = redata.name
                        this.form.date_start =formatDate(new Date(redata.date_start*1000), 'yyyy-MM-dd')
                        this.form.date_end = formatDate(new Date(redata.date_end*1000), 'yyyy-MM-dd')
                    }
                })
                this.housflag= false
                this.titlels = this.$t('devtable.xgjjr')
                this.centerDialogVisible=true
            }
        },
        handjiaqedit(){
            let pamrst={
                method:'att.restday.edit',
                id:this.id,
                name:this.form.name,
                date_start:Math.round(new Date(this.form.date_start) / 1000),
                date_end:Math.round(new Date(this.form.date_end) / 1000)
            }
            this.$serve(pamrst).then(res=>{
                if(res.data.code == 0){
                    this.$message({
                        type: 'success',
                        message:  this.$t('devtable.xgcg')
                    })
                    this.getholidy()
                    this.housflag=true
                    this.titlels =this.$t('devtable.tjjr')
                    this.centerDialogVisible = false;
                }else{
                    this.$message({
                        type: 'error',
                        message:  this.$t('devtable.xgsb')
                    })                    
                }
            })
            this.form.name=""
            this.form.date_start=""
            this.form.date_end=""
        },
        // 删除回调列表
        clearhand(){
            this.getholidy()
        },
        handlpages(v){
            this.configs.page_index=v
            this.getholidy()
        },
        handleSizeChange(v){
            this.configs.page_index=1
            this.configs.pagesize=v
            this.getholidy()
        },
        handRegister(){
            this.centerDialogVisible=true
        },  
        handFromshow(){
            this.flag = !this.flag
        },
        onSubmit(){

        }
    }
}
</script>
<style lang="less" scoped>
.schedling{
    padding: 20px;
    background-color: #fff;
    .attheadfrom{
        padding: 20px;
        margin-top: 10px;
    }
}
</style>